/* fonts*/

@font-face {
  font-family: GlacialIndifference;
  src: url("fonts/GlacialIndifference-Regular.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap");

/* variables */

:root {
  --color-primary: #000000;
  --color-text: #000000;
  --color-link: #787878;

  --ff-logo: "GlacialIndifference", sans-serif;
  --ff-title: "GlacialIndifference", sans-serif;
  --ff-serif: "GlacialIndifference", sans-serif;

  --min-cloud-width: 10;
  --max-cloud-width: 300;

  --contact-height: 13em;
}

/****/

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  font-family: var(--ff-serif);
  font-size: 1.25em;
  color: var(--color-text);
  letter-spacing: 0.5px;
  line-height: 1.2em;
  text-align: center;
  background: #fefefe;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

img {
  display: block;
}

.container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

/* typography */

body,
ul,
h1,
h2,
h3,
h4,
p,
input,
textarea {
  margin: 0;
  padding: 0;
  font-family: var(--ff-serif);
  color: var(--color-primary);
  font-size: 1em;
}

code {
  font-size: 0.8em;
}

h1,
h2,
h3 {
  font-family: var(--ff-title);
  color: var(--color-primary);
  line-height: 1.2;
}

h1 {
  font-size: 2.9em;
  margin-top: 0em;
  margin-bottom: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

h2 {
  font-size: 1.2rem;
  margin-top: 1em;
  margin-bottom: 1em;
  letter-spacing: 1px;
  font-weight: 200;
}

.main h1 {
  margin-top: 0em;
  margin-bottom: 0em;
}

.main h2 {
  font-size: 1.2rem;
  margin-top: 0em;
  margin-bottom: 1em;
}

h3 {
  font-size: 1em;
  font-weight: 200;
  margin-bottom: 0.5em;
  letter-spacing: 1px;
}

p {
  margin-bottom: 0.3em;
  margin-top: 1em;
  line-height: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
}

a {
  text-decoration: none;
  color: var(--color-text);
}

a:hover,
a:hover span {
  text-decoration: underline;
  color: var(--color-link);
}

/* layout */

header {
  width: 80%;
  padding-bottom: 4em;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 auto;
}

header p {
  margin-top: 2em;
}

.small-header {
  height: 3em;
  padding-bottom: 0;
}

.small-header #logo-svg {
  height: inherit;
  width: 3em;
  margin-top: 0.2em;
}

.small-header span {
  vertical-align: middle;
  line-height: 3em;
  display: inline-block;
}

.small-header #logo-link {
  vertical-align: middle;
  line-height: 3em;
  display: inline-block;
}

.small-header .back-link {
  height: inherit;
  width: auto;
  float: right;
  font-size: 1em;
  vertical-align: middle;
  line-height: 3em;
  margin-top: 0em;
}

section {
  margin: 5em auto;
  text-align: center;
}

section p {
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
}

section a {
  opacity: 1;
}

section a:hover {
  opacity: 0.9;
  cursor: pointer;
}

section span {
  font-size: 0.8em;
}

section .pictures {
  height: 20em;
  overflow: hidden;
  margin-top: 2em;
}

section .pictures img {
  width: 10em;
  margin: 0;
  display: inline-block;
  margin-left: 1px;
  margin-top: -20px;
}

.project-title {
  display: block;
  margin-bottom: 2em;
}

.project-title .button-group * {
  display: inline-block;
  height: 2em;
  width: auto;
}

code {
  border: 1px solid #ccccccaa;
  padding: 0.2em;
}

footer {
  width: 80%;
  padding: 4em 0;
  border-top: 1px solid #e0e0e0;
  margin: 0 auto;
}

#contact-button:hover {
  cursor: pointer;
  opacity: 0.5;
}

#contact-wrapper {
  margin-top: 3em;
  /* height: 0px;
    overflow: hidden;
    
    transition: height 0.5s; */
}

#contact-form {
  width: 50%;
  padding: 1em;
  margin: 0em auto;
  text-align: left;
}

input,
textarea {
  width: 100%;
  background-color: #f8f8f8;
  color: #505050;
  padding: 0.5em;
  margin: 0.5em 0 1em 0;
  border: 1px solid #00000022;
  border-radius: 0.2em;
  font-size: 1em;
}

label {
  font-weight: bold;
}

input::placeholder,
textarea::placeholder {
  color: #ffffff99;
}

.tools {
  margin-top: 3em;
}

.tools a {
  height: 2em;
  display: inline-block;
}

.tools a img {
  filter: invert(100%) grayscale(100%);
  height: inherit;
  opacity: 0.2;
}

.tools a.active img,
.tools a:hover img {
  filter: invert(100%);
  opacity: 1;
}
#tool-description h2 {
  text-align: left;
  padding-left: 1em;
}
#tool-description p {
  text-align: left;
  margin-left: 0;
  margin-right: 0;
}
#tool-description div {
  max-width: 25em;
  float: right;
}
#tool-description img {
  width: 12em;
  float: left;
  padding-top: 1px;
  padding-left: 1px;
}

/* Portrait */

@media (max-width: 800px) {
  header h1 {
    font-size: 1.2em;
  }

  h1 {
    font-size: 1.5em;
  }

  #logo-svg {
    height: 12em;
    width: 100%;
    margin-top: 0em;
  }

  #tool-description div {
    max-width: 6.5em;
  }
  #tool-description img {
    width: 6em;
  }

  #contact-form {
    width: 100%;
  }

  #contact-form input,
  #contact-form textarea {
    font-size: 1em;
  }
}
